<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>

          <v-spacer></v-spacer>

          <div
            v-show="showStationsSelect"
            class="mt-5 col-md-2"
          >
            <v-col
              class="d-flex"
            >
              <v-select
                :value="selectedStation"
                mt-50
                :items="stations"
                item-text="name"
                item-value="id"
                label="Station"
                :loading="isStationsLoading"
                dense
                @change="selectedStationChange"
              ></v-select>
            </v-col>
          </div>

          <!-- Right Content -->
          <theme-switcher></theme-switcher>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span> version {{ version }}</span>
          <span class="d-sm-inline d-none">
            <h5>
              &copy; {{ year }}. Made with
              <v-icon>
                {{ icons.mdiHeart }}
              </v-icon>
              for a better web.
            </h5>
          </span>
        </div>
      </div>
    </v-footer>
    <auto-logout />
    <v-snackbar
      id="update-banner"
      v-model="showUpdateNotification"
      :multi-line="true"
      :timeout="-1"
      :right="true"
    >
      A new version is available. Reload page to update.
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          color="primary"
          @click="reloadPage"
        >
          Reload
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import {
  mdiHeart, mdiMagnify, mdiBellOutline, mdiGithub,
} from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import AutoLogout from '@/components/partials/AutoLogout.vue'

const APP_VERSION = require('../../package.json').version

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    AutoLogout,
  },
  data: () => ({
    stations: [],
    showUpdateNotification: false,
    isStationsLoading: true,
    selected_station: '',
    showStationsSelect: true,
    routesToHideStationsSelect: ['dashboard', 'stations', 'main-meters', 'settings'],
  }),
  computed: {
    ...mapGetters(['selectedStation', 'shouldRefreshStations']),
    currentRouteName() {
      return this.$route.name
    },
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiHeart,
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
      year: new Date().getFullYear(),
      version: APP_VERSION,
    }
  },
  watch: {
    shouldRefreshStations(refresh) {
      if (refresh) {
        this.getStations()
      }
    },
    currentRouteName(name) {
      if (this.shouldHideStationSelect(name)) {
        this.showStationsSelect = false
      } else {
        this.showStationsSelect = true
      }
    },
  },
  mounted() {
    this.getStations()
    if (this.shouldHideStationSelect(this.currentRouteName)) {
      this.showStationsSelect = false
    } else {
      this.showStationsSelect = true
    }
    this.shouldHideStationSelect(this.currentRouteName)
  },
  methods: {
    ...mapActions(['setSelectedStation', 'refreshStations']),
    getStations() {
      this.isStationsLoading = true
      axios
        .get('meter-stations')
        .then(response => {
          this.stations = response.data
          if (this.selectedStation === null || this.selectedStation === '') {
            this.setSelectedStation(response.data[0].id)
          }
          this.isStationsLoading = false
          this.refreshStations(false)
        })
        .catch(error => {
          this.isStationsLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    selectedStationChange(value) {
      this.setSelectedStation(value)
    },
    shouldHideStationSelect(routeName) {
      return this.routesToHideStationsSelect.includes(routeName)
    },
    reloadPage() {
      this.showUpdateNotification = false
      location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
