import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import VueNotification from '@kugatsu/vuenotification'
import NProgress from 'vue-nprogress'
import Vue from 'vue'
import axios from 'axios'
import * as LocationPicker from 'vue2-location-picker'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import '@/filters/index'
import wb from './registerServiceWorker'

Vue.config.productionTip = false
Vue.use(VueNotification, {
  timer: 10,
  position: 'topRight',
  showCloseIcn: true,
  infiniteTimer: false,
})
Vue.use(NProgress)
Vue.use(LocationPicker, {
  installComponents: false, // If true, create it globally
})
Vue.prototype.$workbox = wb

const nprogress = new NProgress()

axios.defaults.headers.common.Authorization = `Bearer ${store.getters.userInfo.authenticationToken}`
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

new Vue({
  router,
  store,
  vuetify,
  nprogress,
  render: h => h(App),
}).$mount('#app')
