import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['userPermissions']),
  },
  methods: {
    can(key) { return this.userPermissions.includes(key) },
    removeActionsHeader() {
      this.headers.pop()
    },
  },
}
