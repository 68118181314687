<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="$vuetify.theme.dark"
      @click="setTheme()"
    >
      {{ isDarkThemeEnabled ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isDarkThemeEnabled']),
  },
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
  methods: {
    ...mapActions(['toggleDarkTheme']),
    setTheme() {
      this.toggleDarkTheme()
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
  },
}
</script>

<style>
</style>
